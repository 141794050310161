import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

import { GET_LOGOUT_URL, GET_REFRESH_AUTH_TOKEN_URL, POST_LOGIN_URL } from "../middleware/routes";
import { IAuthState, Status } from "../types/state";
import { ICredentials } from "../types/user";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getJwtFromLocalStorage, removeJwtFromLocalStorage, setJwtToLocalStorage } from "../utils/localStorageHandlers";

export const initAuthState: IAuthState = {
  status: Status.idle,
  errorMsg: null,
  jwt: getJwtFromLocalStorage(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initAuthState,
  reducers: {
    resetAuthState: () => {
      return { ...initAuthState, jwt: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(loginUserThunk.fulfilled, (state, action) => {
        state.status = Status.success;
        state.jwt = action.payload.authToken;
        state.errorMsg = "";
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.status = Status.error;
        if (action.payload) {
          state.errorMsg = action.payload;
        }
      })
      .addCase(initAuthTokenWithRefreshToken.fulfilled, (state, action) => {
        state.status = Status.success;
        state.jwt = action.payload.authToken;
        state.errorMsg = "";
      })
      .addCase(initAuthTokenWithRefreshToken.rejected, (state) => {
        state.status = Status.error;
      })
      .addCase(renewAuthTokenWithRefreshTokenThunk.fulfilled, (state, action) => {
        state.status = Status.success;
        state.jwt = action.payload.authToken;
        state.errorMsg = "";
      })
      .addCase(renewAuthTokenWithRefreshTokenThunk.rejected, (state) => {
        state.status = Status.error;
      });
  },
});

export const loginUserThunk = createAsyncThunk<any, ICredentials, { rejectValue: string }>(
  "user/loginUserThunk",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosRequestApi.post(
        POST_LOGIN_URL,
        {
          login: credentials.email,
          password: credentials.password,
        },
        { withCredentials: true }
      );

      if (response.status === 400 || response.status === 401) {
        const isArray = Array.isArray(response.data.message);
        const message = isArray ? response.data.message[0] : response.data.message;

        const errorMessage = i18next.t(`errors.${message}`);
        return rejectWithValue(errorMessage);
      }

      removeJwtFromLocalStorage();
      setJwtToLocalStorage(response.data.authToken);
      return response.data;
    } catch (err: any) {
      const errorMessage = i18next.t(`errors.${err.message}`);
      return rejectWithValue(errorMessage);
    }
  }
);

export const initAuthTokenWithRefreshToken = createAsyncThunk("user/initAuthTokenWithRefreshToken", async () => {
  const response = await axiosRequestApi.get(GET_REFRESH_AUTH_TOKEN_URL, { withCredentials: true });

  removeJwtFromLocalStorage();
  setJwtToLocalStorage(response.data.authToken);
  return response.data;
});

export const renewAuthTokenWithRefreshTokenThunk = createAsyncThunk(
  "user/renewAuthTokenWithRefreshTokenThunk",
  async () => {
    const response = await axiosRequestApi.get(GET_REFRESH_AUTH_TOKEN_URL, { withCredentials: true });

    removeJwtFromLocalStorage();
    setJwtToLocalStorage(response.data.authToken);
    return response.data;
  }
);

export const logoutUserThunk = createAsyncThunk("user/logoutUserThunk", async () => {
  try {
    await axiosRequestApi.get(GET_LOGOUT_URL, { timeout: 2000 });
  } catch (err) {
    console.log(err);
  }
  removeJwtFromLocalStorage();
  window.location.reload();
});

export const { resetAuthState } = authSlice.actions;

export const authReducer = authSlice.reducer;
