import { useTranslation } from "react-i18next";

import styles from "./LoginPageFooter.module.scss";

export const LoginPageFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <p>
          <span className={styles.bold}>{t("welcome.hello")} </span>
          <span>{t("welcome.login")}</span>
        </p>
      </div>
      <div className={styles.centerSection}>
        <span>Developed by</span>
        <img src="icons/koalaLogo.svg" />
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightSectionContent}>
          <p className={styles.transparent}>
            &#169; {t("Ewe")}, {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};
