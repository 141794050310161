import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CustomButton } from "../../../components/CustomButton";
import { IconNameEnum } from "../../../components/Icon";
import { TextInput, TextInputVariant } from "../../../components/TextInput";
import { loginUserThunk } from "../../../reducers/authReducer";
import { getUserErrorMsg, getUserStatus } from "../../../selectors/auth";
import { Status } from "../../../types/state";
import { ICredentials } from "../../../types/user";

import styles from "./LoginForm.module.scss";

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isError = useSelector(getUserStatus) === Status.error;
  const errorMsg = useSelector(getUserErrorMsg);

  const [credentials, setCredentials] = useState<ICredentials>({
    email: "",
    password: "",
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setCredentials({ ...credentials, [name]: value });
  };

  const login = async (): Promise<void> => {
    dispatch(loginUserThunk(credentials));
  };

  const handleLogin = (): void => {
    if (credentials.email.length > 0 && credentials.password.length > 0) login();
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className={styles.loginForm}>
      <div className={styles.inputs}>
        <TextInput
          error={isError}
          helperText={""}
          required={true}
          placeHolder={t("userName")}
          name="email"
          type="email"
          value={credentials.email}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          autoComplete="email"
          icon={IconNameEnum.username}
          iconSize={24}
          variant={TextInputVariant.secondary}
        />
        <TextInput
          error={isError}
          helperText={errorMsg}
          required={true}
          placeHolder={t("password")}
          name="password"
          type="password"
          value={credentials.password}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          autoComplete="user-password"
          icon={IconNameEnum.password}
          iconSize={24}
          variant={TextInputVariant.secondary}
        />
      </div>
      <CustomButton onClick={handleLogin} text={t("login")} className={styles.loginBtn} />
    </div>
  );
};
