import { LogoutButton } from "../LogoutButton";
import { ModalLanguageSwitcher } from "../ModalLanguageSwitcher";
import { PageHeaderTitle } from "../PageHeaderTitle/PageHeaderTitle";
import { SearchBar } from "../SearchBar";

import styles from "./PageHeaderContainer.module.scss";

interface PageHeaderContainerProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
  backTo?: string;
  RightComponent?: React.ReactNode;
  showSearchBar?: boolean;
  search?: string;
  showLogoutButton?: boolean;
  showLanguageSwitcher?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
}

export const PageHeaderContainer: React.FC<PageHeaderContainerProps> = ({
  children,
  title,
  subtitle,
  backTo,
  RightComponent,
  showSearchBar = false,
  search = "",
  showLogoutButton = true,
  showLanguageSwitcher = true,
  onChange,
  onSearch,
}) => {
  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      onSearch?.();
    }
  };

  return (
    <div className={styles.pageHeader}>
      <PageHeaderTitle title={title} subtitle={subtitle} backTo={backTo} />
      {children}
      {showSearchBar && <SearchBar value={search} onChange={onChange} onSearch={onSearch} onKeyDown={handleKeyPress} />}
      <div className={styles.rightContainer}>
        {showLanguageSwitcher && <ModalLanguageSwitcher />}
        {showLogoutButton && <LogoutButton />}
        {RightComponent && RightComponent}
      </div>
    </div>
  );
};
